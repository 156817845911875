<template>
    <div class="label">
        <div class="label-wall">
            <div class="regular-triangle-left"></div>
            <div class="right-triangle-left"></div>
            <div class="label-main"> {{ specific || '' }} </div>
            <div class="regular-triangle-right"></div>
            <div class="right-triangle-right"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'label',
        watch: {
            '$route.query': function (newVal, oldVal) {
                if (newVal.moduleName) {
                    this.specific = newVal.moduleName
                }
            },
        },
        mounted() {
            this.specific = this.$route.query.moduleName
        },
        data() {
            return {
                specific: '',
            }
        },
    }
</script>

<style scoped lang="less">
    .label {
        position: absolute;
        z-index: 1;
        top: -0.2rem;
        display: flex;
        justify-content: center;
        .label-wall {
            display: flex;
            position: relative;
            height: 0.68rem;
            .regular-triangle-left {
                border-left: 0.1rem solid transparent;
                border-right: 0.1rem solid transparent;
                border-bottom: 0.2rem solid #8ce4d8;
                position: absolute;
                top: 0;
                left: -0.1rem;
                z-index: -1;
            }
            .right-triangle-left {
                width: 0;
                height: 0;
                position: relative;
                border-top: 0.7rem solid #1cd4bd;
                border-left: 0.24rem solid transparent;
            }
            .label-main {
                padding: 0 0.4rem;
                height: 0.68rem;
                background-color: #1cd4bd;
                font-size: 0.36rem;
                font-weight: bold;
                color: #ffffff;
                text-align: center;
                line-height: 0.68rem;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 8rem;
                overflow: hidden;
            }
            .regular-triangle-right {
                border-left: 0.1rem solid transparent;
                border-right: 0.1rem solid transparent;
                border-bottom: 0.2rem solid #8ce4d8;
                position: absolute;
                top: 0;
                right: -0.09rem;
                z-index: -1;
            }
            .right-triangle-right {
                width: 0;
                height: 0;
                border-top: 0.7rem solid #1cd4bd;
                border-right: 0.24rem solid transparent;
                position: relative;
                right: 0.01rem;
            }
        }
    }
</style>
